import * as React from "react";
import './App.css';

function App() {
  return (
    <>
      <form method="POST" action="/submit" enctype="multipart/form-data">
        
        <label for="prompt"> Prompt: </label>
        <input type="text" id="prompt" name="prompt"/>

        <label for="image"> Image: </label>
        <input type="file" id="image" name="image"/>

        <label for="mask"> Mask: </label>
        <input type="file" id="mask" name="mask"/>

        <input type="submit" value="Submit"/>
    
      </form>
    </>
  );
}

export default App;
